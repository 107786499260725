//
// testi.scss
//

/*********************************/
/*         Testimonial           */
/*===============================*/
.customer-testi {
    cursor: e-resize;
    .content {
        &:before {
            content: "";
            position: absolute;
            bottom: -3px;
            left: 50%;
            transform: translateX(50%);
            box-sizing: border-box;
            border: 8px solid $dark;
            border-color: transparent $white $white transparent;
            transform-origin: 0 0;
            transform: rotate(45deg);
            box-shadow: 2px 2px 2px -1px rgba($dark, 0.15);
        }
    }
}

.avatar{
    background-image: cover;
}
.tns-nav {
    text-align: center;
    margin-top: 10px;
    button {
        border: 4px solid rgba($primary, 0.5) !important;
        border-radius: 2px;
        transition: all 0.5s ease;
        border: none;
        margin: 0 3px;
        padding: 1px;
        &.tns-nav-active {
            border-color: $primary !important;
        }
    }
}

//Tns control
.tns-controls {
    button[data-controls="prev"],
    button[data-controls="next"] {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: $white;
        color: $dark;
        border: none;
        transition: all 0.5s ease;
        z-index: 1;
        box-shadow: $shadow-lg;
        &:hover {
            background: $primary;
            color: $white;
        }
    }
    button[data-controls="prev"] {
        left: 0;
    }
    button[data-controls="next"] {
        right: 0;
    }
}

//Bootstrap Carousel
.testi-carousel {
    .carousel-indicators {
        position: relative;
        button {
            width: 80px;
            height: 80px;
            margin-right: 0px;
            margin-left: 0px;
            overflow: hidden;
            border: none;
            background: transparent;
            transition: all 0.5s ease;
        }
        [data-bs-target] {
            background: transparent !important;
        }
        .active {
            background: transparent !important;
            img {
                height: 80px;
                width: 80px;
            }
        }
    }
    .carousel-item {
        .testi-box {
            max-width: 750px;
        }
    }

    .carousel-control-next-icon, 
    .carousel-control-prev-icon {
        display: inline-block;
        width: 40px;
        height: 40px;
        background-color: $primary;
        background-size: 100% 40%;
        border-radius: 50%;
    }
    .carousel-control-next, 
    .carousel-control-prev,
    .carousel-control-next:focus, 
    .carousel-control-next:hover, 
    .carousel-control-prev:focus, 
    .carousel-control-prev:hover {
        opacity: 1;
    }
}