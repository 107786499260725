//
// price.scss
//

/*********************************/
/*         Pricing & Plans       */
/*===============================*/

.pricing-rates {
    position: relative;
    z-index: 1;
    transition: all 0.5s ease;

    &.starter-plan {
        transform: scale(1.075);
        z-index: 2;
        
        @media (max-width: 768px) {
            transform: scale(1);
        }
    }
}

.ribbon {
    @each $name,
    $value in $colors {
        &.ribbon-#{$name} {
            span {
                background-color: #{$value} !important;
                &:before {
                    border-left-color: darken($value, 10%);
                    border-top-color: darken($value, 10%);
                }
        
                &:after {
                    border-right-color: darken($value, 10%);
                    border-top-color: darken($value, 10%);
                }
            }
        }
    }
}

.ribbon {
    position: absolute;
    top: -6px;
    width: 75px;
    height: 75px;
    z-index: 2;
    &.ribbon-right {
        right: -4px;
        span {
            right: -23px;
            transform: rotate(45deg);
        }
    }
    &.ribbon-left {
        left: -5px;
        span {
            left: -21px;
            transform: rotate(-45deg);
        }
    }
    span {
        position: absolute;
        top: 18px;
        width: 100px;
        color: $white;
        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 100%;
            z-index: -1;
            border: 3px solid transparent;
        }
        &:before {
            left: 0;
        }

        &:after {
            right: 0;
        }
    }
}

//Switcher-pricing
.switcher-pricing {
    .toggle, .toggler {
        display: inline-block;
        vertical-align: middle;
    }
    
    .toggler {
        transition: all 0.5s ease;
        font-weight: bold;
    }
    
    .toggler--is-active {
        color: $primary !important;
    }

    .form-switch {
        .form-check-input {
            width: 48px;
            height: 24px;
            margin-top: 0;
        }
    }
}

.hide{
    display: none;
}
